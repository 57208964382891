import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import Layout from "./Layout";
import { Link } from "gatsby";

const CREATE_CUSTOMER = gql`
  # create a customer
  mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customer {
        firstName
        lastName
        email
        phone
      }
      customerUserErrors {
        field
        message
      }
    }
  }
`;
const RegisterPage = () => {
  const [firstName, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [alert, setAlert] = useState(null);

  const handleSubmit = () => {
    const formData = {
      firstName,
      lastName,
      email,
      phone,
      password,
    };

    const { ...customer } = formData;

      customerCreate({
        variables: { input: customer },
        onError(err) {
          console.log(err)
          setAlert("Unexpected error occured, try again later");
        },
        onCompleted: (result) => {
          if (result.customerCreate) {
            if (result.customerCreate.customerUserErrors.length > 0) {
              setAlert(result.customerCreate.customerUserErrors[0].message);
              setTimeout(() => {
                setAlert(null);
              }, 5000);
            } else {
              window.location.assign("/account");
            }
          }
        },
      });

  };

    const [customerCreate, {loading}] = useMutation(CREATE_CUSTOMER);
  return (
    <Layout>
      <main className="container max-w-sm mx-auto px-4 py-8">
        <h1 className="text-center mb-6 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
          Register
        </h1>
        {alert ? (
          <div
            className="max-w-sm mx-auto bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <span className="block sm:inline">{alert}</span>
          </div>
        ) : (
          ""
        )}

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className="mb-6">
            <label
              htmlFor="firstName"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              First name
            </label>
            <input
              type="text"
              id="firstName"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="First name"
              required
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="lastName"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Last name
            </label>
            <input
              type="text"
              id="lastName"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Last name"
              required
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Your email
            </label>
            <input
              type="email"
              id="email"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="name@domain.com"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="phone"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Your phone
            </label>
            <input
              type="tel"
              id="phone"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Your password
            </label>
            <input
              type="password"
              id="password"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="repeat-password"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Repeat password
            </label>
            <input
              type="password"
              id="repeat-password"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
              onChange={(e) => setRepeatPassword(e.target.value)}
            />
          </div>
          <button
            disabled={loading}
            type="submit"
            className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Register new account
          </button>
        </form>
        <div className="mt-3 text-md max-w-sm mx-auto">
          <Link className="text-gray-500" to="/account">
            Back to login
          </Link>
        </div>
        <div className="mt-1 text-md max-w-sm mx-auto">
          <Link to="/forget" className="text-gray-500">
            Forgot your password?
          </Link>
        </div>
      </main>
    </Layout>
  );
};

export default RegisterPage;

export const Head = () => <title>Register Page</title>;
